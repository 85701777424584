// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.REACT_APP_NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  if (typeof window !== 'undefined') {
    return `${window.location.origin}`;
  }

  return '';
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const fetchCallback = res => {
  let headers = res.headers;
  let contentTypeHeader;
  if (headers['content-type'] !== undefined) {
    contentTypeHeader = res.headers['content-type'];
  } else if (res.headers.get !== undefined) {
    contentTypeHeader = res.headers.get('Content-Type');
  }
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

  if (res.status >= 400) {
    if (res.json != undefined) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    throw new Error();
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  } else if (contentType === 'application/json') {
    return res.json != undefined ? res.json() : res.data;
  }
  return res.data !== undefined ? res.data : res.text();
};

const get = path => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };

  if (typeof window !== 'undefined') {
    return window.fetch(url, options).then(fetchCallback);
  }

  options.url = url;
  return axios.request(options).then(fetchCallback);
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };

  if (typeof window !== 'undefined') {
    return window.fetch(url, options).then(fetchCallback);
  }

  options.url = url;
  return axios.request(options).then(fetchCallback);
};

// Send notifications emails to admins
export const sendAdminNotification = body => {
  return post('/api/admin-notifications', body);
};

// Send notifications emails to admin when a booking is done
export const sendAdminBookingNotification = body => {
  return post('/api/admin-booking-notifications', body);
};

export const sendBookingConfirmationToCoAccount = body => {
  return post('/api/co-account-booking-confirmation', body);
};

export const setTransactionProps = body => {
  return post('/api/set-transaction-props', body);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getStripeSubscription = body => {
  return post('/api/get-subscription', body);
};

export const callToGetAvailablePlans = body => {
  return get('/api/get-stripe-plans');
};

export const callToCreateSubscription = body => {
  return post('/api/create-stripe-subscription', body);
};

export const callToCancelPlan = body => {
  return post('/api/cancel-stripe-plan', body);
};

export const sendUserSubscriptionCreated = body => {
  return post('/api/user-subscription-created', body);
};

export const sendUserSubscriptionCancelled = body => {
  return post('/api/user-subscription-cancelled', body);
};

export const sendCoAccountInvitation = body => {
  return post('/api/co-account-send-invitation', body);
};

//get nylas authentication

export const getNylasAuthWindow = redirectUrl => {
  //return get(`/api/nylas/auth?redirect=${redirectUrl}`);
  return get(`/api/nylas/auth`);
};

export const getNylasGrantCode = (code, userId, isOwner = false, listingId = null) => {
  return get(
    `/api/oauth/exchange?code=${code}&user=${userId}&isOwner=${isOwner}&listingId=${listingId}`
  );
};

export const createNylassCalendarEvent = body => {
  return post('/api/nylas/create-event', body);
};

export const deleteNylassCalendarEvent = body => {
  return post(`/api/nylas/delete-event`, body);
};
