import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { IconArrowHead, PrimaryButton } from '../../components';
import config from '../../config';

import css from './EditListingConnectNylass.module.css';
import { getNylasAuthWindow } from '../../util/api';

const EditListingConnectCalendarPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onNextTab,
    onPreviousTab,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const userName = currentUser ? currentUser.attributes.profile.firstName : '';

  const spaceTypeOptions = findOptionsForSelectFilter('spaceType', config.custom.filters);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <button
          type="button"
          className={css.backButton}
          onClick={() => {
            onPreviousTab();
          }}
        >
          <IconArrowHead direction={'left'} isBig={true} />
        </button>
        <div className={css.stepActive}>
          <FormattedMessage
            id="EditListingConnectCalendarPanel.currentStep"
            values={{
              b: chunks => <span className={css.focus}>{chunks}</span>,
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingConnectCalendarPanel.nextStep" />
        </div>
        <button
          type="button"
          className={css.backButton}
          onClick={() => {
            onNextTab();
          }}
        >
          <IconArrowHead direction={'right'} isBig={true} />
        </button>
      </div>
      <div>
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingCConnectCalendarPanel.greeting"
            values={{
              name: <strong> {userName}</strong>,
              b: crunk => <b>{crunk}</b>,
            }}
          />
        </p>
        <div className={css.stypeButton}>
          <PrimaryButton
            style={{ marginTop: '5px' }}
            onClick={() => {
              localStorage.setItem('listing', currentListing.id.uuid);
              const data = getNylasAuthWindow();
              data
                .then(res => {
                  window.location.href = res.authUrl;
                })
                .catch(e => {
                  console.log(e);
                  localStorage.removeItem('listing');
                });
            }}
          >
            <FormattedMessage id="EditListingCConnectCalendarPanel.buttonText" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

EditListingConnectCalendarPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingConnectCalendarPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingConnectCalendarPanel;
